<template>
  <vs-card>
    <vs-row
      style="padding-bottom: 30px"
    >
      <vs-col
        vs-type="flex"
        vs-justify="end"
        vs-align="left"
        vs-w="12"
        v-can="permissions.add"
      >
        <vs-button
          icon-pack="feather"
          icon="icon-plus"
          class="mr-4"
          @click="createEmergency"
        >
          إضافة حالة جديدة
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col
        vs-lg="4"
        vs-sm="8"
        vs-xs="12"
        class="p-1"
        v-for="emergency in emergencies"
        :key="emergency.id"
      >
        <tm-card
          :title="emergency.name"
          :img="emergency.images[0]"
          :link="`/donations/emergencyDetails/${emergency.programId}/${emergency.id}`"
          :complete-percentage="emergency.completePercentage"
        />
      </vs-col>
    </vs-row>
    <store-popup
      :emergency="emergency"
      title="إضافة حالة"
      ref="storePopupRef"
      @created="emergencyCreated"
    />
  </vs-card>
</template>

<script>
import TmCard from "@/app/donations-website/components/TmCard.vue";
import StorePopup from './emergencyPopup.vue';
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";
import utilities from "@/app/shared/utilities";

const emergenciesRepo = RepositoryFactory.get("emergenciesRepository");

export default {
  name: "Emergencies",
  components: {
    TmCard,
    StorePopup
  },
  data() {
    return {
      emergencies: [],
      index: 0,
      isLoading: true,
      emergency: {
        id: null,
        programId: this.$route.params.id,
        name: '',
        description: '',
        location: '',
        status: '',
        targetAmount: '',
        completePercentage: '',
        remainingAmount: '',
        collectedAmount: '',
        finishedAt: '',
        images: [],
        order: '',
        family_name: '',
        family_number: ''
      },
      permissions: {
        add: "store_emergencies",
        update: "update_emergencies",
        delete: "destory_emergencies"
      }
    };
  },
  methods: {
    redirect(route) {
      this.$router.push({name: route});
    },
    async fetchAllEmergencies() {
      this.emergencies = await emergenciesRepo.fetchAllEmergencies(this.$route.params.id);
      this.isLoading = false;
    },
    createEmergency() {
      utilities.initObjectMembers(this.emergency);
      this.$refs.storePopupRef.open();
    },
    emergencyCreated(emergency) {
      this.emergencies.push(emergency);
    },
  },
  async created() {
    await this.fetchAllEmergencies();
  },
};
</script>

<style scoped>
.vs-switch-primary {
  background: #d94d4d;
}
</style>
